import {
    ReportTypeCode,
    TAnnotatedDataPoint,
    TContent,
    TGSTProperty,
    TGSTPropertyLabels,
    TInscriptionGraphic,
    TJewelryReportData,
    TLabel,
    TLabelCategory,
    TLabels,
    TReportData,
    TReportDataPayload,
    TReportField,
    TReportHeader,
    TReportSections,
    TReportTypeCode,
    TStructuredReportData,
    TTableItem,
} from './types';

/**
 *
 * @param {string} input - The input to validate
 * @returns {boolean}
 */
export function isReportTypeCode(input: string): input is TReportTypeCode {
    return Object.keys(ReportTypeCode).includes(input as TReportTypeCode);
}

/**
 * Checks if a given property is a valid GST Property.
 *
 * @param {string} property - The property name to check.
 * @returns {boolean}
 */
export function isValidGSTProperty(property: TGSTProperty): property is TGSTProperty {
    return property.startsWith('gia_www_i18n_');
}

/**
 * Type guard to check if a report is a structured format report
 *
 * @param {TReportDataPayload} report - The report data to check
 * @returns True if the report uses the structured format
 */
export function isStructuredReport(report: TReportDataPayload): report is TStructuredReportData {
    return typeof report === 'object' && report !== null && 'HEADER' in report && 'SECTIONS' in report;
}

/**
 * Type guard to check if a report is a QAD report
 *
 * @param {TReportDataPayload} report - The report data to check
 * @returns True if the report is a QAD report
 */
export function isQADReport(report: TReportDataPayload): report is TStructuredReportData {
    return report.REPORT_TYPE_CODE === 'QAD';
}

/**
 * Type guard to check if a report is a standard report (TReportData)
 * that is neither a jewelry report nor a structured report
 *
 * @param {TReportDataPayload} report - The report data to check
 * @returns True if the report is a standard report
 */
export function isStandardGradingReport(report: TReportDataPayload): report is TReportData {
    // First check that it's not a jewelry report
    if (report.REPORT_TYPE_CODE === 'JG' || report.REPORT_TYPE_CODE === 'JGC') {
        return false;
    }

    // Then check that it's not a structured report (QAD or structured jewelry)
    if ('HEADER' in report && 'SECTIONS' in report) {
        return false;
    }

    // Must be a standard report
    return true;
}

/**
 * Type guard to check if a report is a Jewelry Grading Report
 *
 * @param {TReportDataPayload} report - The report data to check
 * @returns True if the report is a Jewelry Grading Report
 */
export function isJewelryReport(report: TReportDataPayload): report is TJewelryReportData {
    return report.REPORT_TYPE_CODE === 'JG' || report.REPORT_TYPE_CODE === 'JGC';
}

export { ReportTypeCode };
export type {
    TAnnotatedDataPoint,
    TContent,
    TGSTProperty,
    TGSTPropertyLabels,
    TInscriptionGraphic,
    TJewelryReportData,
    TLabel,
    TLabelCategory,
    TLabels,
    TReportData,
    TReportDataPayload,
    TReportField,
    TReportHeader,
    TReportSections,
    TReportTypeCode,
    TStructuredReportData,
    TTableItem,
};

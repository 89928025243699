import React from 'react';
import { TAnnotatedDataPoint } from '@@config/report';

// Import component-scoped CSS (CSS Modules)
import * as styles from './Bullet.module.css';

type TBulletProps = {
    readonly item: TAnnotatedDataPoint;
    readonly index?: number;
};

const Bullet: React.FC<TBulletProps> = ({ item, index }): React.JSX.Element => {
    return (
        <li className={`${styles['bullet']} ml-4 py-1`} key={item.KEY ?? index}>
            {item.VALUE}
        </li>
    );
};

export default Bullet;
export type { TBulletProps };

import React from 'react';
import { TAnnotatedDataPoint } from '@@config/report';

// Import component-scoped CSS (CSS Modules)
import * as styles from './Disclaimer.module.css';

type TDisclaimerProps = {
    readonly title: string;
    readonly items: TAnnotatedDataPoint[];
};

const Disclaimer: React.FC<TDisclaimerProps> = ({ title, items }): React.JSX.Element => {
    return (
        <div className={`${styles['disclaimer']} alert alert-light rounded-xl py-3 my-3`}>
            <h4 className='sub-section-header mt-2 mb-3'>{title}</h4>
            {items.map((item, i) => {
                if (item.KIND === 'text') {
                    return (
                        <p className={`mb-0 ${styles['disclaimerText']}`} key={item.KEY ?? `disclaimer-item-${i}`}>
                            {item.VALUE}
                        </p>
                    );
                }
                return null;
            })}
        </div>
    );
};

export default Disclaimer;

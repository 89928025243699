import React, { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { TKeyToSymbol } from '@@components/KeyToSymbol/KeyToSymbol';
import InscriptionRow from '@@components/InscriptionRow/InscriptionRow';
import OriginImageRows from '@@components/OriginImageRows/OriginImageRows';
import Table from '@@components/Table/Table';
import TableRow from '@@components/TableRow/TableRow';
import { TLocale } from '@@config/locale';
import {
    TGSTPropertyLabels,
    TReportTypeCode,
    TTableItemData,
    TTableItem,
    TLabelCategory,
    TContent,
    TReportData,
} from '@@config/report';
import { LocaleContext, TLocaleContext } from '@@contexts/LocaleContext';
import { getDiamondOriginDisclaimer, getKeysToSymbols, getReportCheckJSONURL, mergeData } from '@@utils/utils';

type TGradingReportProps = {
    readonly data: TReportData;
    readonly i18n: TGSTPropertyLabels;
};

const fetchReportCheckJsonContent = async (reportType: TReportTypeCode, locale: TLocale): Promise<TContent> => {
    const url: URL = getReportCheckJSONURL(reportType, locale);
    const request = new Request(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    });
    const response = await fetch(request);

    if (!response.ok) {
        throw new Error('Failed to fetch Report Check Template JSON');
    }

    const json = await response.json();
    return json;
};

const GradingReport: React.FC<TGradingReportProps> = ({ data, i18n }) => {
    const { locale }: TLocaleContext = useContext(LocaleContext);
    const reportDate: Date = new Date(data['REPORT_DT'] ?? '');
    const reportNumber: string = data['REPORT_NO'] ?? '';
    const reportTypeCode: TReportTypeCode = data['REPORT_TYPE_CODE'];
    const kts: TKeyToSymbol[] = getKeysToSymbols(data['CLARITY_CHARACTERISTICS'] ?? '', data['KTS_IMG'] ?? '');
    const { ORGPOL, ORGROU, INSCRIPTION_GRAPHICS } = data;

    const dorDisclaimer = getDiamondOriginDisclaimer(
        reportNumber,
        reportDate,
        i18n['gia_www_i18n_olddordisclaimer'] ?? '',
        i18n['gia_www_i18n_dordisclaimer'] ?? ''
    );

    const {
        data: content,
        isLoading,
        error,
    } = useQuery<TContent>({
        queryKey: ['reportCheckJson', reportTypeCode, locale],
        queryFn: () => fetchReportCheckJsonContent(reportTypeCode, locale),
        staleTime: 10 * 60 * 1000, // 10 minutes
        gcTime: 30 * 60 * 1000, // 30 minutes
        select: jsonData => mergeData(jsonData, data),
    });

    if (isLoading) {
        return (
            <div className='row pr-md-3'>
                <div className='col'>
                    <div className='skeleton w-100' style={{ height: 'min(65vh, 50rem)' }}></div>
                </div>
            </div>
        );
    }

    if (error) {
        console.error(error);
        return <div>Error loading report content</div>;
    }

    if (content) {
        return (
            <>
                {content['LABEL_CATEGORIES'].map((category: TLabelCategory) => (
                    <Table key={category['SECTION_TITLE']} title={category['SECTION_TITLE']}>
                        {category['ITEMS'].map((item: TTableItem) => {
                            const key = Object.keys(item)[0]!;

                            if (key === 'COUNTRY_OF_ORIGIN') {
                                const tableItemData: TTableItemData = item[key]!;
                                return (
                                    <>
                                        <TableRow key={key} tableItemKey={key} tableItemData={tableItemData} />
                                        {ORGPOL && ORGROU && (
                                            <OriginImageRows
                                                originPolishedImage={ORGPOL}
                                                originRoughImage={ORGROU}
                                                disclaimer={dorDisclaimer}
                                                imagesCaption={i18n['gia_www_i18n_dorimgcaption'] ?? ''}
                                            />
                                        )}
                                    </>
                                );
                            }

                            if (item[key]?.KIND === 'image' && key === 'PLOTIMG') {
                                const tableItemData: TTableItemData = item[key];
                                return (
                                    <TableRow
                                        key={key}
                                        tableItemKey={key}
                                        tableItemData={tableItemData}
                                        keyToSymbols={kts}
                                    />
                                );
                            }

                            if (item[key]?.KIND === 'inscription' && INSCRIPTION_GRAPHICS?.length) {
                                const tableItemData: TTableItemData = item[key];
                                return (
                                    <InscriptionRow
                                        key={key}
                                        tableItemKey={key}
                                        tableItemData={tableItemData}
                                        graphics={INSCRIPTION_GRAPHICS}
                                    />
                                );
                            }

                            if (key) {
                                const tableItemData: TTableItemData = item[key]!;
                                return <TableRow key={key} tableItemKey={key} tableItemData={tableItemData} />;
                            }

                            return null;
                        })}
                    </Table>
                ))}
            </>
        );
    }

    return (
        <div className='row pr-md-3'>
            <div className='col'>
                <div className='skeleton w-100' style={{ height: 'min(65vh, 50rem)' }}></div>
            </div>
        </div>
    );
};

export default GradingReport;

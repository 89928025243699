import React, { useContext } from 'react';
import { useLoaderData } from 'react-router';

// Components
import AdditionalInformation from '@@components/AdditionalInformation/AdditionalInformation';
import DataError, { TErrorCode } from '@@components/DataError/DataError';
import JewelryGradingReport from '@@components/JewelryGradingReport/JewelryGradingReport';
import StandardGradingReport from '@@components/StandardGradingReport/StandardGradingReport';
import QADReport from '@@components/QADReport/QADReport';
import ReportBasicInfo from '@@components/ReportBasicInfo/ReportBasicInfo';
import ReportSupplementals from '@@components/ReportSupplementals/ReportSupplementals';
import ReportSupplemental from '@@components/ReportSupplemental/ReportSupplemental';

// Types and utilities
import {
    isJewelryReport,
    isQADReport,
    isStandardGradingReport,
    TJewelryReportData,
    TLabels,
    TReportData,
    TReportTypeCode,
    TStructuredReportData,
} from '@@config/report';
import { TLocale } from '@@config/locale';
import { LocaleContext, TLocaleContext } from '@@contexts/LocaleContext';
import { getAWSEnv, getElectronicReportURL, getReportCheckLandingURL, pushEvent } from '@@utils/utils.ts';

// Define loader data type
type LoaderData = {
    reportData: TReportData | TJewelryReportData | TStructuredReportData;
    labels: TLabels;
    error?: { code: TErrorCode; message: string };
    locale: TLocale;
    reportno?: string;
};

const Results: React.FC = () => {
    const { locale }: TLocaleContext = useContext(LocaleContext);
    const loaderData = useLoaderData() as LoaderData;
    const { reportData, labels, error, reportno: requestedReportNo } = loaderData;

    // Handle errors
    if (error?.code === '401') {
        window.location.href = getReportCheckLandingURL(requestedReportNo, locale);
        return null;
    }

    if (error) {
        return <DataError errorCode={error.code} fallbackMessage={error.message} />;
    }

    // Push analytics events in production
    if (getAWSEnv() === 'prod') {
        pushEvent('reportType', reportData.REPORT_TYPE ?? '');
        pushEvent('reportDate', reportData.REPORT_DT ?? '');
    }

    // Common data preparation regardless of report type
    const reportNo = reportData.REPORT_NO;

    const reportTypeCode: TReportTypeCode = reportData.REPORT_TYPE_CODE;

    // Determine primary CTA (Call To Action) URL
    let primaryCTA: URL | undefined = undefined;

    if (isStandardGradingReport(reportData) && reportTypeCode === 'DER') {
        primaryCTA = getElectronicReportURL(reportNo, reportData?.WEIGHT ?? '');
    } else if (reportData.HEADER?.PDF_URL?.VALUE) {
        primaryCTA = new URL(reportData.HEADER['PDF_URL'].VALUE);
    }

    return (
        <main id='main-container' className='container-default my-4 mx-auto' style={{ minHeight: 'auto' }}>
            <section className='d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-start'>
                <ReportBasicInfo
                    reportNumber={reportData.HEADER['REPORT_NO']}
                    reportDate={reportData.HEADER['REPORT_DT']}
                />

                {isJewelryReport(reportData) && (
                    <ReportSupplementals
                        reportTypeCode={reportTypeCode}
                        reportNumber={reportNo}
                        primaryCTA={primaryCTA}
                        labels={labels}
                    />
                )}

                {isStandardGradingReport(reportData) && (
                    <div className='d-none d-md-block'>
                        <ReportSupplemental
                            reportTypeCode={reportTypeCode}
                            reportNumber={reportNo}
                            primaryCTA={primaryCTA}
                            reportDAC={reportData.DIGITAL_CARD ?? ''}
                            reportDTL={reportData.DTL_PDF_URL ?? ''}
                            reportDTLP={reportData.DTLP_PDF_URL ?? ''}
                            reportDTTL={reportData.DTTL_PDF_URL ?? ''}
                            i18n={labels.legacy}
                        />
                    </div>
                )}
            </section>

            <hr className='dotted-double mt-sm-1 mt-md-3' />

            {isQADReport(reportData) && <QADReport data={reportData} />}

            {isJewelryReport(reportData) && <JewelryGradingReport data={reportData} labels={labels} />}

            {isStandardGradingReport(reportData) && (
                <StandardGradingReport primaryCTA={primaryCTA ?? undefined} data={reportData} labels={labels} />
            )}

            {!isStandardGradingReport(reportData) && <AdditionalInformation reportTypeCode={reportTypeCode} />}
            <hr className='dotted mt-sm-1 mt-md-5' />
        </main>
    );
};

export default Results;

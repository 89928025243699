import React from 'react';
import { parseHTML } from '@@utils/utils.ts';
import { TAliasModule } from '@@config/wcs/';

// Import component-scoped CSS (CSS Modules)
import * as styles from './InfoCard.module.css';

type TCardProps = {
    readonly data: TAliasModule;
};

const InfoCard: React.FC<TCardProps> = ({ data }) => {
    const { DESCRIPTION, IMAGE, TITLE } = data;

    return (
        <div className='col my-3 my-lg-0'>
            <div className='card info-card p-2 p-lg-4 border-0'>
                <div className='d-flex justify-content-center justify-content-sm-start'>
                    {IMAGE && <img className={styles['cardIcon']} src={IMAGE.SRC} alt={IMAGE.ALT_TEXT} />}
                </div>
                <div className='card-body p-0'>
                    <h5 className={`${styles['cardTitle']} card-title text-uppercase text-center text-sm-left`}>
                        {TITLE}
                    </h5>
                    <p className='card-text text-center text-sm-left'>{parseHTML(DESCRIPTION)}</p>
                </div>
            </div>
        </div>
    );
};

export type { TCardProps };
export default InfoCard;

import React from 'react';
import { TAnnotatedDataPoint } from '@@config/report';
import Bullet from '@@components/Bullet/Bullet';
import Note from '@@components/Note/Note';

type TListProps = {
    readonly title: string;
    readonly items: TAnnotatedDataPoint[];
};

const List: React.FC<TListProps> = ({ title, items }): React.JSX.Element => {
    return (
        <>
            <h3 className='sub-section-header my-2'>{title}</h3>
            <div className='mx-2'>
                <ul>
                    {items.map((item, index) => {
                        const itemKey = item.KEY ?? `list-item-${index}`;

                        if (item.KIND === 'bullet') {
                            return <Bullet key={itemKey} item={item} index={index} />;
                        }

                        if (item.KIND === 'note') {
                            return <Note key={itemKey} item={item} index={index} />;
                        }

                        return null;
                    })}
                </ul>
            </div>
        </>
    );
};

export default List;

import React from 'react';
import { TAnnotatedDataPoint } from '@@config/report';

// Import component-scoped CSS (CSS Modules)
import * as styles from './Note.module.css';

type TNoteProps = {
    readonly item: TAnnotatedDataPoint;
    readonly index?: number;
};

const Note: React.FC<TNoteProps> = ({ item, index }): React.JSX.Element => {
    return (
        <li className={`${styles['note']} ml-0 my-3 my-md-2`} key={item.KEY ?? index}>
            <strong className='text-dark'>Note:&nbsp;</strong>
            {item.VALUE}
        </li>
    );
};

export default Note;
export type { TNoteProps };

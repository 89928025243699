import { LoaderFunction } from 'react-router';
import { TLocale } from '@@config/locale';
import { getAWSEnv, getCookie, getRdwbApiHost, parseReportResponse } from '@@utils/utils';
import {
    isJewelryReport,
    isStructuredReport,
    TJewelryReportData,
    TReportData,
    TReportDataPayload,
    TStructuredReportData,
} from '@@config/report';

export const reactResultsLoader: LoaderFunction = async ({ request }): Promise<Response> => {
    const url: URL = new URL(request.url);
    const reportno = url.searchParams.get('reportno');
    const qr = url.searchParams.get('qr') === 'true';
    const locale = url.searchParams.get('locale') ?? ('en_US' as TLocale);

    const AWS_ENV = getAWSEnv();
    const RDWB_API_HOST = getRdwbApiHost();

    const rdwbToken = await getCookie('rdwb-token');

    if (!rdwbToken && AWS_ENV !== 'local') {
        console.warn('[RDWB] Authorization Token Not Found');
        return Response.json({
            error: { code: '401', message: 'Temporary Authorization Token not found.' },
            reportno: reportno,
        });
    }

    const rdwbTokenEncoded = rdwbToken.replace('/*', '%2f*');
    const rdwbEndpoint: URL = new URL(
        `${RDWB_API_HOST}?reportno=${reportno}&locale=${locale}&env=${AWS_ENV}&USEREG=1&qr=${qr}`
    );

    try {
        const apiResponse = await fetch(rdwbEndpoint.toString(), {
            method: 'GET',
            credentials: AWS_ENV === 'local' ? 'omit' : 'include',
            headers: {
                'Accept': 'application/json',
                'Cache-Control': 'no-cache',
                'Rdwb-Token': rdwbTokenEncoded,
            },
            cache: 'no-cache',
        });

        if (!apiResponse.ok) {
            throw new Error(`HTTP Error >> Status: ${apiResponse.status}`);
        }
        console.debug('[RDWB] apiResponse?.ok: ', apiResponse.ok);

        const response = (await apiResponse.json()) as TReportDataPayload;

        if ('ERROR_CODE' in response) {
            return Response.json({ error: { code: response.ERROR_CODE, message: 'An error occurred' } });
        }

        // Check for structured reports first, then handle legacy formats
        if (isStructuredReport(response)) {
            // Handle structured reports (QAD and new Jewelry reports)
            const { reportData, labels } = parseReportResponse<TStructuredReportData>(response);
            return Response.json({ reportData, labels, locale });
        } else if (isJewelryReport(response)) {
            // Handle legacy jewelry reports
            const { reportData, labels } = parseReportResponse<TJewelryReportData>(response);
            return Response.json({ reportData, labels, locale });
        } else {
            // Handle standard gem/diamond reports
            const { reportData, labels } = parseReportResponse<TReportData>(response);
            return Response.json({ reportData, labels, locale });
        }
    } catch (error) {
        console.error('Loader error:', error);
        return Response.json({ error: { code: '500', message: 'Internal server error' } });
    }
};

import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import parse from 'html-react-parser';
import Fancybox from '@@components/Fancybox/Fancybox';
import ImageLoader from '@@components/ImageLoader/ImageLoader';
import GradingReportTables from '@@components/GradingReportTables/GradingReportTables';
import LabGrownBanner from '@@components/LabGrownBanner/LabGrownBanner';
import ReportSupplemental from '@@components/ReportSupplemental/ReportSupplemental';
import RightRail from '@@components/RightRail/RightRail';
import SupplementalsAccordion from '@@components/SupplementalsAccordion/SupplementalsAccordion';
import SVSAccordion from '@@components/SVSAccordion/SVSAccordion';
import ProvenanceAccordion from '@@components/ProvenanceAccordion/ProvenanceAccordion';
import AGSReport from '@@components/AGSReport/AGSReport';
import { TLabels, TReportData } from '@@config/report';

type StandardGradingReportProps = {
    primaryCTA?: URL | undefined;
    data: TReportData;
    labels: TLabels;
};

const StandardGradingReport: React.FC<StandardGradingReportProps> = ({ primaryCTA, data, labels }) => {
    const REPORT_TYPE_CODE = data.REPORT_TYPE_CODE;
    const REPORT_NO = data.REPORT_NO;
    const REPORT_DT_FORMATTED = new Date(data.REPORT_DT).toLocaleDateString();
    const CUT_GRADE = data?.CUT_GRADE ?? '';

    // Boolean flags for display logic
    const isNaturalDiamond = ['DD', 'CI', 'CD', 'FC', 'DER', 'DG', 'DOR', 'CDOR'].includes(REPORT_TYPE_CODE);
    const isLabGrown = ['LGDOSS', 'LGDG', 'LGCD', 'LGCI', 'LGDR'].includes(REPORT_TYPE_CODE);

    // Determine if we need to show tabs for supplemental content
    const showTabs: boolean = Boolean(
        data?.['IS_AGS'] === 'TRUE' ||
            data['COR_DATA']?.length ||
            data['GROWTH_METHOD_DATA']?.length ||
            data['SVS_DATA']?.length ||
            data['TREATMENTS_DATA']?.length ||
            (data['TRACR_DATA']?.length && data?.['TRACR_ID'])
    );

    return (
        <div className='row report-details-and-sidebar no-gutters'>
            {isLabGrown && (
                <div className='col-12'>
                    <LabGrownBanner reportTypeCode={REPORT_TYPE_CODE} i18n={labels.legacy} />
                </div>
            )}
            <div className='col-sm-12 col-md-9'>
                <div className='row mt-3 mb-5'>
                    <div className='col'>
                        <h1 id='REPORT_TYPE' className='text-left bg-none letter-spacing-normal pb-0 mb-0'>
                            {parse(data?.['REPORT_TYPE'])}
                        </h1>
                        {isNaturalDiamond && (
                            <span id='REPORT_TYPE_SUBTITLE' className='text-uppercase font-weight-bold'>
                                FOR NATURAL DIAMOND
                            </span>
                        )}
                    </div>
                    {data?.['DGTIMG'] && (
                        <div className='col-sm-12 col-md-3'>
                            <div className='mr-0 mr-md-3'>
                                <Fancybox>
                                    <a href={data['DGTIMG']} data-fancybox>
                                        <ImageLoader
                                            className='img-fluid'
                                            src={data['DGTIMG']}
                                            minHeight={125}
                                            alt='...'
                                        />
                                    </a>
                                </Fancybox>
                            </div>
                        </div>
                    )}
                </div>

                {!showTabs && (
                    <div className='row'>
                        <div className='col-12'>
                            <GradingReportTables data={data} i18n={labels.legacy} />
                        </div>
                    </div>
                )}

                {showTabs && (
                    <Tabs
                        defaultActiveKey='report-details'
                        className='supp-tabs d-flex justify-content-center text-uppercase my-3 border-0'
                    >
                        <Tab eventKey='report-details' title='Report Details'>
                            <div className='row'>
                                <div className='col-12'>
                                    <GradingReportTables data={data} i18n={labels.legacy} />
                                </div>
                            </div>
                        </Tab>

                        {data['COR_DATA'] && (
                            <Tab eventKey='country-of-origin' title='Country of Origin'>
                                <SupplementalsAccordion supplementalContent={data['COR_DATA']} />
                            </Tab>
                        )}

                        {data?.['TREATMENTS_DATA'] && !data?.['GROWTH_METHOD'] && (
                            <Tab eventKey='treatment' title='Treatment'>
                                <SupplementalsAccordion supplementalContent={data['TREATMENTS_DATA']} />
                            </Tab>
                        )}

                        {data?.['GROWTH_METHOD_DATA'] && (
                            <Tab eventKey='growth-method' title='Growth Method'>
                                <SupplementalsAccordion supplementalContent={data['GROWTH_METHOD_DATA']} />
                            </Tab>
                        )}

                        {data?.['SVS_DATA'] && (
                            <Tab
                                eventKey='svs'
                                title={parse(`<span>GIA<sup>®</sup> Source Verification Service</span>`)}
                            >
                                <SVSAccordion
                                    svsName={data?.['SVS_NAME'] ?? ''}
                                    supplementalContent={data['SVS_DATA']}
                                />
                            </Tab>
                        )}

                        {data?.['TRACR_DATA'] && (
                            <Tab eventKey='provenance' title='Provenance'>
                                <ProvenanceAccordion
                                    tracrId={data['TRACR_ID']}
                                    provenanceName={data?.['PROVENANCE_NAME']}
                                    supplementalContent={data['TRACR_DATA']}
                                />
                            </Tab>
                        )}

                        {data?.['IS_AGS'] === 'TRUE' && (
                            <Tab eventKey='ags' title={parse(`AGS Ideal<sup>&reg;</sup> Report`)}>
                                <AGSReport
                                    REPORT_DT_FORMATTED={REPORT_DT_FORMATTED}
                                    REPORT_NO={REPORT_NO}
                                    AGS_SHAPE={data.AGS_SHAPE ?? ''}
                                    AGS_MEASUREMENTS={data.AGS_MEASUREMENTS ?? ''}
                                    AGS_CARAT_WEIGHT={data.AGS_CARAT_WEIGHT ?? ''}
                                    AGS_LIGHT_PERFORMANCE_GRADE={data.AGS_LIGHT_PERFORMANCE_GRADE ?? ''}
                                    AGS_BRIGHTNESS={data.AGS_BRIGHTNESS ?? ''}
                                    AGS_FIRE={data.AGS_FIRE ?? ''}
                                    AGS_CONTRAST={data.AGS_CONTRAST ?? ''}
                                    AGS_PDF_URL={data.AGS_PDF_URL ?? ''}
                                    AGS_LIGHTIMG={data.AGS_LIGHTIMG ?? ''}
                                />
                            </Tab>
                        )}
                    </Tabs>
                )}
            </div>
            <div className='w-100 d-sm-block d-md-none my-4'>
                <ReportSupplemental
                    reportTypeCode={REPORT_TYPE_CODE}
                    reportNumber={REPORT_NO}
                    primaryCTA={primaryCTA}
                    reportDAC={data.DIGITAL_CARD ?? ''}
                    reportDTL={data.DTL_PDF_URL ?? ''}
                    reportDTLP={data.DTLP_PDF_URL ?? ''}
                    reportDTTL={data.DTTL_PDF_URL ?? ''}
                    i18n={labels.legacy}
                />
            </div>
            <aside id='right-rail' className='col-sm-12 col-md-3'>
                <RightRail reportTypeCode={REPORT_TYPE_CODE} cutGrade={CUT_GRADE} />
            </aside>
        </div>
    );
};

export default StandardGradingReport;

import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { createBrowserRouter, RouterProvider } from 'react-router';
import ErrorPage from '@@components/ErrorPage/ErrorPage';
import Landing from '@@components/Landing/Landing';
import Results from '@@components/Results/Results';
import { reactResultsLoader } from '@@components/Results/Results.loader';
import Root from '@@components/Root/Root';
import { LocaleProvider } from '@@providers/LocaleProvider';
import '@@/styles/scss/index.scss';
import '@@/styles/css/variables.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Creates a client with persistence configuration
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 10 * 60 * 1000, // 10 minutes
            gcTime: 24 * 60 * 60 * 1000, // 24 hours
        },
    },
});

// Create persister
const persister = createSyncStoragePersister({
    storage: window.localStorage,
    key: 'report-check-cache', // Unique key for application cache
});

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/report-check-landing',
                element: <Landing />,
            },
            {
                path: '/report-check',
                element: <Results />,
                loader: reactResultsLoader,
            },
        ],
    },
]);

createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
            <LocaleProvider>
                <RouterProvider router={router} />
            </LocaleProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </PersistQueryClientProvider>
    </React.StrictMode>
);

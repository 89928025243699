// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".disclaimer__rEf8Bp59B {\n  background-color: var(--disclaimer-bg) !important;\n  border-color: var(--disclaimer-border);\n}\n\n.disclaimerText__dAqIX7UqZ {\n  color: var(--disclaimer-text);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Disclaimer/Disclaimer.module.css"],"names":[],"mappings":"AAAA;EACE,iDAAiD;EACjD,sCAAsC;AACxC;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".disclaimer {\n  background-color: var(--disclaimer-bg) !important;\n  border-color: var(--disclaimer-border);\n}\n\n.disclaimerText {\n  color: var(--disclaimer-text);\n}\n"],"sourceRoot":""}]);
// Exports
export var disclaimer = "disclaimer__rEf8Bp59B";
export var disclaimerText = "disclaimerText__dAqIX7UqZ";
export default ___CSS_LOADER_EXPORT___;

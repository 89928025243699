import React from 'react';
import { TStructuredReportData } from '@@config/report';
import Description from '@@components/Description/Description';
import Disclaimer from '@@components/Disclaimer/Disclaimer';
import List from '@@components/List/List';

type QADReportProps = {
    data: TStructuredReportData;
};

const QADReport: React.FC<QADReportProps> = ({ data }) => {
    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <h1 className='h2 bg-none letter-spacing-normal pb-3'>{data.REPORT_TYPE}</h1>
                </div>
            </div>

            {/* Render sections in order based on SECTION_INDEX */}
            <div className='row'>
                {Object.entries(data.SECTIONS)
                    .sort(([, a], [, b]) => a.SECTION_INDEX - b.SECTION_INDEX)
                    .map(([sectionKey, section]) => {
                        switch (section.SECTION_TYPE) {
                            case 'description':
                                return (
                                    <div className='col-12' key={sectionKey}>
                                        <Description title={section.SECTION_TITLE} items={section.ITEMS} />
                                    </div>
                                );
                            case 'list':
                                return (
                                    <div className='col-sm-12 col-md-6' key={sectionKey}>
                                        <List title={section.SECTION_TITLE} items={section.ITEMS} />
                                    </div>
                                );
                            case 'disclaimer':
                                return (
                                    <div className='col-12' key={sectionKey}>
                                        <Disclaimer title={section.SECTION_TITLE} items={section.ITEMS} />
                                    </div>
                                );
                            default:
                                return (
                                    <div className='col-12 d-none' key={sectionKey}>
                                        <code className='text-capitalize'>&lt;{section.SECTION_TYPE} /&gt;</code>
                                    </div>
                                );
                        }
                    })}
            </div>
        </>
    );
};

export default QADReport;

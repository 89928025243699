import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { TReportField } from '@@config/report';
import { LocaleContext, TLocaleContext } from '@@contexts/LocaleContext';
import { getLocaleDayJS } from '@@utils/utils.ts';

type TReportBasicInfoProps = {
    readonly reportNumber: TReportField;
    readonly reportDate: TReportField;
};

const ReportBasicInfo: React.FC<TReportBasicInfoProps> = ({ reportNumber, reportDate }) => {
    const { locale }: TLocaleContext = useContext(LocaleContext);
    const reportDateFormatted = dayjs(reportDate.VALUE).locale(getLocaleDayJS(locale)).format('MMM. D, YYYY');

    return (
        <div className='report-basic-info d-flex mb-2 mb-lg-0'>
            <div className='d-flex flex-column'>
                <h3 className='m-0 p-0 uppercase'>{reportNumber.LABEL}</h3>
                <p id='REPORT_NO' className='mt-1 mt-md-2'>
                    {reportNumber.VALUE}
                </p>
            </div>
            <div className='d-flex flex-column'>
                <h3 className='m-0 p-0 uppercase text-right text-md-left'>{reportDate.LABEL}</h3>
                <p id='REPORT_DT' className='mt-1 mt-md-2 text-right text-md-left'>
                    {reportDateFormatted}
                </p>
            </div>
        </div>
    );
};

export default ReportBasicInfo;
export type { TReportBasicInfoProps };

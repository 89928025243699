// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cardIcon__aNduzJ6_t {\n  max-width: 32px;\n  padding-bottom: 1.5rem;\n}\n\n.cardTitle__I752cnOh4 {\n  font-size: 1rem !important;\n  color: #666 !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/InfoCard/InfoCard.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,0BAA0B;EAC1B,sBAAsB;AACxB","sourcesContent":[".cardIcon {\n  max-width: 32px;\n  padding-bottom: 1.5rem;\n}\n\n.cardTitle {\n  font-size: 1rem !important;\n  color: #666 !important;\n}\n"],"sourceRoot":""}]);
// Exports
export var cardIcon = "cardIcon__aNduzJ6_t";
export var cardTitle = "cardTitle__I752cnOh4";
export default ___CSS_LOADER_EXPORT___;

import parse, { Element, HTMLReactParserOptions } from 'html-react-parser';
import React, { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import LocaleSelector from '@@components/LocaleSelector/LocaleSelector';
import { TLocale } from '@@config/locale/';
import { LocaleContext, TLocaleContext } from '@@contexts/LocaleContext';
import { getReportCheckFooterContentURL } from '@@utils/utils';

const fetchFooterContent = async (locale: TLocale) => {
    const url: URL = getReportCheckFooterContentURL(locale);
    const request = new Request(url, {
        method: 'GET',
        headers: {
            Accept: 'text/html',
        },
    });
    const response = await fetch(request);

    if (!response.ok) {
        throw new Error('Failed to fetch footer content');
    }

    return response.text();
};

const Footer: React.FC = () => {
    const { locale }: TLocaleContext = useContext(LocaleContext);

    const {
        data: content,
        isLoading,
        error,
    } = useQuery({
        queryKey: ['footerContent', locale],
        queryFn: () => fetchFooterContent(locale),
        staleTime: 2 * 60 * 60 * 1000, // 2 hours
    });

    const options: HTMLReactParserOptions = {
        replace: domNode => {
            if ((domNode as Element).attribs && (domNode as Element).attribs['id'] === 'xlocale-selector') {
                return <LocaleSelector />;
            }
            return domNode;
        },
    };

    if (error) {
        console.error(error);
        return <div>Error loading footer content</div>;
    }

    return (
        <footer id='footer' className='footer-container'>
            {!isLoading && content && parse(content, options)}
        </footer>
    );
};

export default Footer;

import React from 'react';
import { TAnnotatedDataPoint } from '@@config/report';

type TDescriptionProps = {
    readonly title: string;
    readonly items: TAnnotatedDataPoint[];
};

const Description: React.FC<TDescriptionProps> = ({ title, items }): React.JSX.Element => {
    return (
        <>
            <h3 className='sub-section-header my-2'>{title}</h3>
            <div className='mx-2'>
                {items.map((item, index) => (
                    <p key={item.KEY ?? `description-item-${index}`}>{item.VALUE}</p>
                ))}
            </div>
        </>
    );
};

export default Description;

import { useQuery } from '@tanstack/react-query';
import parse, { domToReact, DOMNode, Element, HTMLReactParserOptions } from 'html-react-parser';
import React, { useContext } from 'react';
import AppPromo from '@@components/AppPromo/AppPromo';
import GradingScale from '@@components/GradingScale/GradingScale';
import { TLocale } from '@@config/locale/';
import { TReportTypeCode } from '@@config/report';
import { LocaleContext, TLocaleContext } from '@@contexts/LocaleContext';
import { getBaseURL, getReportCheckRightRailContentURL } from '@@utils/utils';

const fetchRightRailContent = async (reportTypeCode: TReportTypeCode, locale: TLocale) => {
    const url: URL = getReportCheckRightRailContentURL(reportTypeCode, locale);
    const request = new Request(url, {
        method: 'GET',
        headers: {
            Accept: 'text/html',
        },
    });
    const response = await fetch(request);

    if (!response.ok) {
        throw new Error('Failed to fetch right rail content');
    }

    return response.text();
};

type TRightRailProps = {
    reportTypeCode: TReportTypeCode;
    cutGrade: string;
};

const RightRail: React.FC<TRightRailProps> = ({ reportTypeCode, cutGrade = '' }) => {
    const { locale }: TLocaleContext = useContext(LocaleContext);

    const hasCutGrade = cutGrade !== '';

    const {
        data: content,
        isLoading,
        error,
    } = useQuery({
        queryKey: ['rightRailContent', locale, reportTypeCode],
        queryFn: () => fetchRightRailContent(reportTypeCode, locale),
        staleTime: 2 * 60 * 60 * 1000, // 2 hours
    });

    const makeAbsoluteUrl = (relativeUrl: string): string => {
        const baseUrl = getBaseURL();
        return new URL(relativeUrl, baseUrl).toString();
    };

    const options: HTMLReactParserOptions = {
        replace: domNode => {
            if (domNode instanceof Element) {
                // Handle <a> tags
                if (domNode.name === 'a' && domNode?.attribs['href']) {
                    const href = domNode.attribs['href'];
                    const childNodes = domNode.children as DOMNode[];

                    if (href.startsWith('/') || href.startsWith('./')) {
                        return <a href={makeAbsoluteUrl(href)}>{domToReact(childNodes, options)}</a>;
                    }
                }

                // Handle <img> tags
                if (domNode.name === 'img' && domNode?.attribs['src']) {
                    const src = domNode.attribs['src'];
                    if (src.startsWith('/') || src.startsWith('./')) {
                        return <img {...domNode.attribs} src={makeAbsoluteUrl(src)} alt={domNode.attribs['alt']} />;
                    }
                }

                // Show the Grading Scales for Diamond Dossier reports only
                if (reportTypeCode === 'DD') {
                    if ((domNode as Element)?.attribs['id'] === 'xgrading-scale') {
                        return <GradingScale hasCutGrade={hasCutGrade} locale={locale} />;
                    }
                }

                if ((domNode as Element)?.attribs['id'] === 'xapp-promo') {
                    return <AppPromo locale={locale} />;
                }
            }

            return domNode;
        },
    };

    if (isLoading) {
        return (
            <div className='row h-100 px-0 pl-md-3'>
                <div className='col'>
                    <div className='skeleton w-100 h-100'></div>
                </div>
            </div>
        );
    }

    if (error) {
        console.error(error);
        return <div>Error loading header content</div>;
    }

    if (content) {
        return parse(content, options);
    }

    return (
        <div className='row h-100 px-0 pl-md-3'>
            <div className='col'>
                <div className='skeleton w-100 h-100'></div>
            </div>
        </div>
    );
};

export default RightRail;

import { TSupplementalContent } from '@@config/wcs';
import { TLocale } from '@@config/locale';

/**
 * An enumeration of the standard report type codes available via the RR API.
 *
 * @enum
 */
export enum ReportTypeCodes {
    AL = 'Alexandrite Report',
    ALO = 'Alexandrite Origin Report',
    ALOTR = 'Alexandrite Origin & Traceability Report',
    CCR = "Cat's Eye Chrysoberyl Report",
    CD = 'Colored Diamond Grading Report',
    CDOR = 'Colored Diamond Origin Report',
    CI = 'Colored Diamond Identification and Origin Report',
    CPC = 'Cultured Pearl Classification Report',
    DD = 'Diamond Dossier Report',
    DDO = 'Diamond Dossier with Origin Report',
    DER = 'Diamond eReport, Diamond e-Report',
    DG = 'Diamond Grading Report',
    DOR = 'Diamond Grading with Origin Report',
    EO = 'Emerald Origin Report',
    EOTR = 'Emerald Origin & Traceability Report',
    ER = 'Emerald Report',
    FC = 'Diamond Focus Report',
    ID = 'Identification Report',
    IDTO = 'Identification & Origin Report, Identification Origin Report',
    IDTT = 'Identification Origin & Traceability Report',
    JG = 'Jewelery Grading Report',
    JGC = 'Jewelery Grading Report Card',
    JR = 'Jade Report',
    LGCD = 'Laboratory-Grown Colored Diamond Grading Report, Laboratory-Grown Colored Diamond Report',
    LGCI = 'Laboratory-Grown Colored Diamond Report - Color Identification',
    LGDG = 'Laboratory-Grown Diamond Grading Report, Laboratory-Grown Diamond Report',
    LGDOSS = 'Laboratory-Grown Diamond Report - Dossier',
    MLE = 'Melee Analysis Service',
    PG = 'Pearl Identification and Classification Report',
    PI = 'Pearl Identification Report, Pearl Classification Report',
    QAD = 'Quality Assurance Report',
    RG = 'Ruby Report',
    RO = 'Ruby Origin Report',
    ROTR = 'Ruby Origin & Traceability Report',
    SCD = 'Synthetic Colored Diamond Grading Report',
    SCI = 'Synthetic Diamond Identification Report',
    SDG = 'Synthetic Diamond Grading Report',
    SG = 'Sapphire Report',
    SO = 'Sapphire Origin Report',
    SOTR = 'Sapphire Origin & Traceability Report',
    SPOR = 'Spinel Origin Report',
    SPOTR = 'Spinel Origin & Traceability Report',
    SPR = 'Spinel Report',
    TOR = 'Tourmaline Origin Report',
    TOTR = 'Tourmaline Origin & Traceability Report',
    TR = 'Tourmaline Report',
}

/**
 * An enumeration of report type codes exported as constant readonly strings
 *
 * @const
 * @enum
 */
export const ReportTypeCode = {
    ...ReportTypeCodes,
} as const;

/**
 * A string literal type representing the keys of the `ReportTypeCode` enum.
 *
 * @type
 */
export type TReportTypeCode = keyof typeof ReportTypeCodes;

/**
 * Represents the naming convention for the Oracle WebCenter Sites "GSTProperty"
 * These properties provide simple and single values for i18n strings.
 *
 * @type
 */
export type TGSTProperty = `gia_www_i18n_${string}`;

/**
 * Represents a dictionary object where each key follows the GSTProperty naming
 * convention. The data source for these internationalized properties is the
 * Oracle WebCenter Sites CMS.
 *
 * @type
 */
export type TGSTPropertyLabels = {
    [key: TGSTProperty]: string;
};

/**
 * Metadata structure for the labels section.
 * Contains version information and locale settings.
 */
export type TLabelsMeta = {
    version: `${number}.${number}.${number}`;
    locale: TLocale;
};

/**
 * Represents a label object with three allowed or expected properties: label,
 * text, and heading.
 *
 * @type
 */
export type TLabel = {
    readonly heading?: string;
    readonly text?: string;
    readonly label?: string;
    readonly tooltip?: string;
};

/**
 * A type definition for a dictionary object of labels for i18n.
 *
 * @type
 */
export type TLabels = {
    legacy: TGSTPropertyLabels;
    [key: string]: TLabel;
};

/**
 * A dictionary object representing a table item where each key maps to
 * a TTableItemData structure. In practice, each TTableItem contains exactly
 * one key that corresponds to a key in TReportData.
 *
 * @type
 */
export type TTableItem = {
    [key: string]: TAnnotatedDataPoint;
};

/**
 * Represents a category of labels within a table, containing a section title
 * and an array of table items.
 *
 * @type
 */
export type TLabelCategory = {
    SECTION_TITLE: string;
    ITEMS: TTableItem[];
};

/**
 * Structure for table content containing an array of label categories.
 *
 * @type
 */
export type TContent = {
    LABEL_CATEGORIES: TLabelCategory[];
};

/**
 * Represents a field with label and value typically for report headers
 *
 * @type
 */
export type TReportField = {
    LABEL: string;
    VALUE: string;
};

/**
 * Represents the header section of a report
 *
 * @type
 */
export type TReportHeader = {
    REPORT_NO: TReportField;
    REPORT_DT: TReportField;
    PDF_URL?: TReportField;
};

/**
 * Represents the different item kinds that can appear in report sections
 *
 * @type
 */
export type TDataPointKind =
    | 'data'
    | 'text'
    | 'bullet'
    | 'note'
    | 'disclaimer'
    | 'image'
    | 'inscription'
    | 'report_check_link';

/**
 * Represents a single item within a report section (historically restricted to
 * table rows or cells)
 *
 * @type
 */
export type TAnnotatedDataPoint = {
    KIND: TDataPointKind;
    VALUE: string;
    TOOLTIP?: string;
    LABEL?: string;
    KEY?: string;
};

/**
 * Represents the different section types in reports
 *
 * @type
 */
export type TReportSectionType = 'description' | 'list' | 'disclaimer' | 'table' | 'hero' | 'multi-table';

/**
 * Represents a section in the structured report format
 *
 * @type
 */
export type TReportSection = {
    SECTION_INDEX: number;
    SECTION_TYPE: TReportSectionType;
    SECTION_TITLE: string;
    ITEMS: TAnnotatedDataPoint[];
};

/**
 * Represents all sections in a structured report
 *
 * @type
 */
export type TReportSections = {
    [sectionKey: string]: TReportSection;
};

/**
 * A type definition for the GIA report summaries that are sometimes present on
 * Jewelry Grading Reports
 *
 * @type
 */
export type TGIAReportSummary = {
    __TYPENAME: string;
    REPORT_NO: string;
    REPORT_TYPE_CODE: string;
    SHAPE: string;
    MEASUREMENTS: string;
    WEIGHT: string;
    COLOR_GRADE: string;
    CLARITY_GRADE: string;
    INSCRIPTION: string;
    CUT_GRADE: string;
    // Adding an index signature to allow dynamic key access
    [key: string]: string;
};

/**
 * A type definition for the additional reports that are sometimes present on
 * Jewelry Grading Reports
 *
 * @type
 */
export type TAdditionalReports = {
    TOTAL_STONES: string;
    STONE_TYPE: string;
    ESTIMATED_CLARITY_RANGE: string;
    ESTIMATED_COLOR_RANGE: string;
    ESTIMATED_TOTAL_CARAT_WEIGHT: string;
    ADDITIONAL_SHAPES: string;
    // Adding an index signature to allow dynamic key access
    [key: string]: string;
};

/**
 * Represents graphical data for an inscription on a gemstone
 *
 * @type
 */
export type TInscriptionGraphic = {
    description: string;
    image: string;
};

/**
 * Universally shared base report data fields
 *
 * @type
 */
export type TBaseReportData = {
    ENCRYPTED_REPORT_NO: string;
    ENCRYPTOR_ENCRYPTED_REPORT_NO: string;
    SIMPLE_ENCRYPTED_REPORT_NO: string;
    HEADER: TReportHeader;
    REPORT_NO: string;
    REPORT_NUMBER?: string;
    REPORT_TYPE: string;
    REPORT_TYPE_CODE: TReportTypeCode;
    REPORT_DT: `${number}/${number}/${number}`;
    REPORT_DATE?: `${number}/${number}/${number}`;
    PDF_URL: string;
    IS_AGS: 'FALSE' | 'TRUE';
    TIME_CACHED: string;
};

/**
 * A type definition for the report data fields that are unique to Jewelry
 * Reports
 *
 * @type
 */
export type TJewelryReportData = TBaseReportData & {
    JEWELRY_TOP_VIEW_IMAGE: string;
    JEWELRY_SIDE_VIEW_IMAGE: string;
    IMAGE_COMMENTS: string;
    JEWELRY_DESCRIPTION: string;
    METAL_TESTED_AS: string;
    ITEMS_WEIGHT: string;
    ENGRAVINGS: string;
    STAMPINGS: string;
    GIA_REPORTS: TGIAReportSummary[];
    ADDITIONAL_REPORTS: TAdditionalReports[];
    // Adding an index signature to allow dynamic key access
};

/**
 * Type definition for the report data fields which are exclusive to diamond or
 * gemstone reports
 *
 * @type
 */
export type TReportData = TBaseReportData & {
    SHAPE?: string;
    MEASUREMENTS?: string;
    WEIGHT?: string;
    COLOR_GRADE?: string;
    CLARITY_GRADE?: string;
    CUT_GRADE?: string;
    DEPTH?: string;
    TABLE?: string;
    CROWN_ANGLE?: string;
    CROWN_HEIGHT?: string;
    PAVILION_ANGLE?: string;
    PAVILION_DEPTH?: string;
    STAR_LENGTH?: string;
    LOWER_HALF?: string;
    GIRDLE?: string;
    GROWTH_METHOD?: string;
    CULET?: string;
    POLISH?: string;
    SYMMETRY?: string;
    FLUORESCENCE?: string;
    CLARITY_CHARACTERISTICS?: string;
    KTS_IMG?: string;
    REPORT_COMMENTS?: string;
    REPORT_SLEEVE_MSG?: string;
    INFO_MSG?: string;
    DTL_PDF_URL?: string;
    DTLP_PDF_UR?: string;
    DTTL_PDF_URL?: string;
    DTLP_PDF_URL?: string;
    PROPIMG?: string;
    DGTIMG?: string;
    ORGPOL?: string;
    ORGROU?: string;
    INSCRIPTION_REFERENCE_ID?: string;
    DIGITAL_CARD?: string;
    INSCRIPTION?: string;
    INSCRIPTION_GRAPHICS?: TInscriptionGraphic[];
    AGS_SHAPE?: string;
    AGS_MEASUREMENTS?: string;
    AGS_CARAT_WEIGHT?: string;
    AGS_LIGHT_PERFORMANCE_GRADE?: string;
    AGS_BRIGHTNESS?: string;
    AGS_FIRE?: string;
    AGS_CONTRAST?: string;
    AGS_PDF_URL?: string;
    AGS_LIGHTIMG?: string;
    PROVENANCE_NAME?: string;
    SVS_NAME?: string;
    TRACR_ID?: string;
    COR_DATA?: TSupplementalContent[];
    SVS_DATA?: TSupplementalContent[];
    TRACR_DATA?: TSupplementalContent[];
    TREATMENTS_DATA?: TSupplementalContent[];
    GROWTH_METHOD_DATA?: TSupplementalContent[];
};

/**
 * Basic structure for all structured format report types
 *
 * @type
 */
export type TStructuredReportData = {
    REPORT_NO: string;
    REPORT_DT: string;
    REPORT_TYPE: string;
    REPORT_TYPE_CODE: TReportTypeCode;
    SIMPLE_ENCRYPTED_REPORT_NO: string;
    ENCRYPTOR_ENCRYPTED_REPORT_NO: string;
    HEADER: TReportHeader;
    SECTIONS: TReportSections;

    // Optional fields that might be present in some report types
    ENCRYPTED_REPORT_NO?: string;
    PDF_URL?: string;
    IS_AGS?: 'FALSE' | 'TRUE';
    TIME_CACHED?: string;

    // These might be present in some jewelry reports
    JEWELRY_TOP_VIEW_IMAGE?: string;
    JEWELRY_SIDE_VIEW_IMAGE?: string;
    IMAGE_COMMENTS?: string;
    JEWELRY_DESCRIPTION?: string;
    METAL_TESTED_AS?: string;
    ITEMS_WEIGHT?: string;
    ENGRAVINGS?: string;
    STAMPINGS?: string;
    GIA_REPORTS?: TGIAReportSummary[];
    ADDITIONAL_REPORTS?: TAdditionalReports[];
};

/**
 * Comprehensive type definition for the Report Data Web Bridge (RDWB) API
 * response. Contains grading data, supplemental content, and metadata for
 * diamond/gemstone reports.
 *
 * @see https://gialab.atlassian.net/wiki/spaces/API1/pages/2981101882/RDWB+Usage
 * @type
 */
export type TReportDataPayload = {
    labels?: TLabels;
} & (TReportData | TJewelryReportData | TStructuredReportData);
